import React, { useEffect, useContext } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"

import NavigationIntersectionObserverContext from "~context/navigationIntersectionObserver"

import Project from "~components/project"

const Information = styled.div`
  width: 80vw;
  margin: 5rem auto;
  text-align: center;
`

export default ({ project }) => {
  if (!project) return null
  const { id, name, description, images, credits, tags, highlight } = project
  const [, setNavigationState] = useContext(
    NavigationIntersectionObserverContext
  )
  const [ref, inView] = useInView({ threshold: 0.1 })

  useEffect(() => {
    if (inView) {
      setNavigationState({
        showContact: false,
        isVisible: true,
        isProject: true,
        data: {
          name: project.name,
          client: project.client,
          description: project.description,
          credits: [project.credits[0]],
          tags: tags,
          highlight: project.highlight.hex,
        },
      })
    }
  }, [inView])

  return (
    <Project key={id} ref={ref}>
      <Project.Hero image={images[0]} projectName={name} />
      <Information>
        <Project.Description description={description} />
        <br />
        <Project.Credits credits={credits} highlight={highlight.hex} />
        <Project.Tags projectTags={tags} highlight={highlight.hex} />
      </Information>
      <Project.Images images={images.slice(1, images.length)} />
    </Project>
  )
}
