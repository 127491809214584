import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Project from "./components/project"

export default ({ data }) => {
  const { sanityProject: project } = data

  const handleLoad = () => {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    handleLoad()
  }, [])

  return <Project project={project} />
}

export const projectQuery = graphql`
  query ProjectQuery($id: String!) {
    sanityProject(id: { eq: $id }) {
      client
      description
      name
      id
      credits {
        credited
        type
        _key
      }
      images {
        ... on SanityImageSet {
          _key
          _type
          images {
            _key
            altText
            name
            asset {
              id
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
              extension
              url
            }
          }
        }
        ... on SanityImageWithAltText {
          _key
          altText
          name
          asset {
            id
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
            extension
            url
          }
        }
        ... on SanityVideoUrl {
          _key
          _type
          videoUrl
        }
      }
      tags {
        id
        name
      }
      highlight {
        hex
      }
    }
  }
`
